<template>
  <page-header-wrapper>
    <div class="a-card">
      <ServiceOrderSearch
        :queryParam="queryParam"
        :totalNum="totalNum"

      />
      <div class="table-operator">

        <a-button type="dashed" style="display:none" @click="tableOption">{{ optionAlertShow && '关闭' || '开启' }} alert
        </a-button>
        <a-dropdown v-action:edit v-if="selectedRowKeys.length > 0">
          <a-menu slot="overlay">
            <a-menu-item class="hide" key="1" click="handleDeleteSelected">
              <a-icon type="delete"/>
              删除
            </a-menu-item>
          <!-- lock | unlock -->
          </a-menu>
          <a-button style="margin-left: 8px">
            批量操作
            <a-icon type="down"/>
          </a-button>
        </a-dropdown>
      </div>
      <s-table
        :bordered="true"
        ref="table"
        :dataSource="data"
        :data="loadData"
        :alert="options.alert"
        :rowSelection="options.rowSelection"
        :row-key="(record, index) => index"
        :pagination="{showSizeChanger: true, showQuickJumper: true, pageSize:20, total: 50}"
        :scroll="{ x: true}">

        <a-table-column :title="$t('columnName.remarkNo')" align="center">
          <template slot-scope="text, record, index">{{index+1}}</template>
        </a-table-column>
        <a-table-column title="操作" align="center">
          <span slot-scope="record">
            <a @click="handleDetail(record)">{{ $t("button.detail") }}</a>
            <a-divider type="vertical"/>
            <a-dropdown v-if="record.serviceOrderStatus=='待确认' || record.serviceOrderStatus=='待处理' " >
              <a class="ant-dropdown-link">
                更多 <a-icon type="down"/>
              </a>
              <a-menu slot="overlay">
                <!-- 设为 待处理  -->
                <a-menu-item v-if="record.serviceOrderStatus=='待确认'">
                  <a-popconfirm title="确认设置状态为[待处理]?" @confirm="() => handlePending(record)">
                    <a>{{ $t('button.pending') }}</a>
                  </a-popconfirm>
                </a-menu-item>
                <!-- 设为 已确认  -->
                <a-menu-item v-if="record.serviceOrderStatus=='待处理'">
                  <a-popconfirm title="确认设置状态为[已完成]?" @confirm="() => handleDecided(record)">
                    <a>{{ $t("button.decided") }}</a>
                  </a-popconfirm>
                </a-menu-item>
                <!-- 设为 已取消  -->
                <a-menu-item v-if="record.serviceOrderStatus=='待确认' || record.serviceOrderStatus=='待处理' ">
                  <a-popconfirm title="确认设置状态为[已取消]?" @confirm="() => handleCanceled(record)">
                    <a>{{ $t("button.canceled") }}</a>
                  </a-popconfirm>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table-column>
        <!-- 服务单号 -->
        <a-table-column :title="$t('columnName.serviceOrderNo')" align="left">
          <template slot-scope="record">{{record.serviceOrderNo}}</template>
        </a-table-column>
        <!-- 服务单状态 -->
        <a-table-column :title="$t('columnName.serviceOrderStatus')" align="center">
          <template slot-scope="record">{{record.serviceOrderStatus}}</template>
        </a-table-column>
        <!-- 服务单提交时间 -->
        <a-table-column :title="$t('columnName.serviceOrderSubmitTime')" align="center">
          <template slot-scope="record">{{formatDateTime(record.serviceOrderSubmitTime)}}</template>
        </a-table-column>
        <!-- 订单项目列表 -->
        <a-table-column :title="$t('columnName.saleOrderItemList')" align="left">
          <div slot-scope="record">
            <span v-for="(item, idx) in record.saleOrderItemList" :key="idx">
              {{item.skuName}}
              <a-tag v-for="(item_attr, idx_attr) in item.saleAttrList" :key="idx_attr" color="blue">
                [{{ item_attr.attrName }}]-[{{ item_attr.valueName }}]
              </a-tag>
              ({{ item.skuNo }}) * {{ item.quantity }}
              <br/>
            </span>
          </div>
        </a-table-column>
         <!-- 商品总金额 -->
        <a-table-column :title="$t('columnName.goodsTotalAmount')" align="right">
          <template slot-scope="record">
            {{formatTypeMoney(record.dealAmount, record.currentType, record.moneyMultiple)}}
          </template>
        </a-table-column>
        <!-- 联系人 -->
        <a-table-column :title="$t('columnName.contactName')" align="left">
          <template slot-scope="record">{{record.contactName}}</template>
        </a-table-column>
        <!-- 联系人电话 -->
        <a-table-column :title="$t('columnName.contactPhone')" align="left">
          <template slot-scope="record">{{record.contactPhone}}</template>
        </a-table-column>
        <!-- 联系人邮箱 -->
        <a-table-column :title="$t('columnName.contactEmail')" align="left">
          <template slot-scope="record">{{record.contactEmail}}</template>
        </a-table-column>
        <!-- 更新时间 -->
        <a-table-column :title="$t('columnName.updateTime')" align="center">
          <template slot-scope="record">{{formatDateTime(record.updateTime)}}</template>
        </a-table-column>
      </s-table>

      <a-modal
        width="50%"
        :title="$t('page.service_order')"
        :visible="serviceOrderModalShow"
        :confirm-loading="confirmEditLoading"
        @ok="handleEditOk"
        @cancel="handleEditCancel"
      >
        <ServiceOrderEdit :serviceOrderData="serviceOrderData"/>
      </a-modal>
      <a-drawer
        :closable="false"
        :visible="showDetailDrawer"
        placement="right"
        width="80%"
        @close="onDetailDrawerClose">
        <ServiceOrderDetail
          ref="memberDetail"
          :options="detailOptions"
          :showDetailDrawer="showDetailDrawer"></ServiceOrderDetail>
      </a-drawer>
      <CancelOprate ref="cancelOprate" @handleOk="handleCanceledEditOk"/>

      <CommentEditor ref="commentEditor" @handleOk="handleCommentOk"/>

    </div>
  </page-header-wrapper>
</template>

<script>
// 演示如何使用 this.$dialog 封装 modal 组件
import {
  changeStatus,
  getServiceOrderCount,
  getServiceOrderData,
  saveServiceOrderData,
  searchServiceOrder
} from '@/api/order/serviceOrder'
import moment from 'moment'
import { STable } from '@/components'

import ServiceOrderSearch from '@/views/order/ServiceOrderSearch'
import ServiceOrderEdit from '@/views/order/components/ServiceOrderEdit'
import ServiceOrderDetail from '@/views/order/ServiceOrderDetail'
import ApprovalEditor from '@/components/approval/ApprovalEditor'
import CommentEditor from '@/components/comment/CommentEditor'
import CancelOprate from '@/components/comment/CancelOprate'

export default {
  name: 'ServiceOrderList',
  components: {
    ApprovalEditor,
    CommentEditor,
    ServiceOrderDetail,
    STable,
    ServiceOrderSearch,
    ServiceOrderEdit,
    CancelOprate
  },
  data () {
    return {
      confirmEditLoading: false,
      showDetailDrawer: false,
      detailOptions: {},

      // 查询参数
      queryParam: { serviceOrderStatus: [] },
      totalNum: {
        waitDecided: 0,
        waitPending: 0
      },
      data: [],
      status: 'all',
      // 加载数据方法 必须为 Promise 对象
      // loadData: parameter => {
      //   console.log('loadData.parameter', parameter)
      //   return getServiceOrder(Object.assign(parameter, this.queryParam))
      //       .then(res => {
      //         return res.result
      //       })
      // },
      selectedRowKeys: [],
      selectedRows: [],
      serviceOrderModalShow: false,
      serviceOrderData: {},
      // custom table alert & rowSelection
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          }
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
    // console.log('this.$route.query) => ', this.$route.query)
    let {serviceOrderId = null, serviceOrderNo = null} = this.$route.query
    if(serviceOrderId && serviceOrderNo) { //售后单详情弹窗点过来的新开页面 带参打开新的售后单详情
      this.handleDetail({serviceOrderId: parseInt(serviceOrderId), serviceOrderNo})
    }
    // getRoleList({t: new Date()})
  },
  mounted () {

  },
  methods: {
    // 加载数据方法 必须为 Promise 对象
    loadData (parameter) {
      return searchServiceOrder(Object.assign(parameter, this.queryParam))
        .then(res => {
          console.log('loadData->searchServiceOrder.parameter', parameter, res)
          getServiceOrderCount(parameter).then((countRes) => {
            console.log('loadData->getServiceOrderCount.parameter', parameter, res)
            this.totalNum = countRes
          }).catch(() => {})
          return res
        })
    },
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            }
          },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },

    handleEdit (record) {
      const that = this
      getServiceOrderData({ service_orderId: record.service_orderId }).then(res => {
        that.service_orderData = res
      }).catch(res => {
        that.$message.error(this.$t('entity.serviceOrder.get.fail'))
      })
      // 弹出编辑页面
      this.service_orderModalShow = true
    },
    handleEditCancel (record) {
      // 弹出编辑页面
      this.service_orderModalShow = false
    },
    handleEditOk (record) {
      const that = this
      saveServiceOrderData(this.service_orderData).then(res => {
        that.$message.success(this.$t('entity.serviceOrder.save.success'))
        this.$refs.table.refresh(true)
      }).catch(res => {
        that.$message.error(this.$t('entity.serviceOrder.save.fail'))
      })
      this.service_orderData = {}
      // 弹出编辑页面
      this.service_orderModalShow = false
    },

    handleDetail (record) {
      let {serviceOrderId, serviceOrderNo} = record
      this.detailOptions = { serviceOrderId, serviceOrderNo}
      this.showDetailDrawer = true
    },

    handlePending (record) {
      const that = this
      record.serviceChangeStatus = '待处理'
      record.serviceChangeStatusDesc = '待处理'
      // this.$refs['commentEditor'].show(record, '服务状态变更')
      record.common = ''
      let params = {
        record: record
      }
      that.handleCommentOk(params)
    },

    handleDecided (record) {
      const that = this
      record.serviceChangeStatus = '已完成'
      record.serviceChangeStatusDesc = '已完成'
      // this.$refs['commentEditor'].show(record, '服务状态变更')
      record.common = ''
      let params = {
        record: record
      }
      that.handleCommentOk(params)
    },

    handleCommentOk (params) {
      const that = this
      const record = params.record
      console.log('handleCommentOk', record)
      const reqParam = {}
      reqParam.serviceOrderStatus = record.serviceChangeStatus
      reqParam.serviceOrderStatusDesc = record.serviceChangeStatusDesc
      reqParam.serviceOrderId = record.serviceOrderId
      reqParam.serviceOrderNo = record.serviceOrderNo
      reqParam.remarkComment = record.comment
      console.log('handleCommentOk', record, reqParam)
      return changeStatus(reqParam)
        .then((res) => {
          console.log('changeStatus->changeStatus', reqParam, res)
          // this.$message.success(this.$t('entity.saleOrder.enable.success'))
          that.$refs.table.refresh(true)
          that.$message.success('提交成功')
        }).catch(() => {
         that.$refs.table.refresh(true)
         that.$message.error('提交失败')
        })
    },

    handleCanceled (record) {
      const that = this
      record.serviceChangeStatus = '已取消'
      record.serviceChangeStatusDesc = '已取消'
      this.$refs['cancelOprate'].show(record)
    },

    handleCanceledEditOk (params) {
      const record = params.record
      const that = this
      console.log('handleCanceledEditOk', record)
      const reqParam = {}
      reqParam.serviceOrderStatus = record.serviceChangeStatus
      reqParam.serviceOrderStatusDesc = record.serviceChangeStatusDesc
      reqParam.serviceOrderId = record.serviceOrderId
      reqParam.serviceOrderNo = record.serviceOrderNo
      reqParam.remarkComment = record.remarkComment
      console.log('handleCanceledEditOk', record, reqParam)
      return changeStatus(reqParam)
        .then((res) => {
          console.log('changeStatus->changeStatus', reqParam, res)
          // this.$message.success(this.$t('entity.saleOrder.enable.success'))
          that.$refs.table.refresh(true)
          that.$message.success('提交成功')
        }).catch(() => {
         that.$refs.table.refresh(true)
         that.$message.error('提交失败')
        })
    },

    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    },
    onDetailDrawerClose () {
    //  this.$refs.table.refresh(true)
      this.showDetailDrawer = false
    }
  }
}
</script>
<style lang="less" scoped>
.ant-avatar-lg {
  width: 48px;
  height: 48px;
  line-height: 48px;
}

.list-content-item {
  color: rgba(0, 0, 0, .45);
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  margin-left: 40px;

  span {
    line-height: 20px;
  }

  p {
    margin-top: 4px;
    margin-bottom: 0;
    line-height: 22px;
  }
}
</style>
