<template>
  <!-- hidden PageHeaderWrapper title demo -->
  <div>

    <div style="text-align: center">
      <a-button-group v-if="initParam.balanceRefund">
        <a-button type="primary">退款到余额</a-button>
        <a-button type="default"  @click="handlePaymentRefund(serviceOrderData)" >原路退回</a-button>
      </a-button-group>
      <a-button-group v-if="initParam.paymentRefund">
        <a-button type="default"  @click="handleBalanceRefund(serviceOrderData)" >退款到余额</a-button>
        <a-button type="primary">原路退回</a-button>
      </a-button-group>
    </div>
    <a-divider/>

    <div v-if="initParam.balanceRefund" class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row justify="center" :gutter="24">
          <a-col :push="6" :md="10" :sm="24" >
            <!--
            <a-form-item
              :label="$t('field.currentType')"
              :labelCol="{lg: {span: 8}, sm: {span: 8}}"
              :wrapperCol="{lg: {span: 8}, sm: {span: 17} }">
              <a-select v-model="initParam.currentType" :placeholder="'请选择'+ this.$t('field.currentType')">
                <a-select-option
                  :disabled="true"
                  v-for="(item, index) in codeList.currentTypeList"
                  :key="index"
                  :value="item.value">{{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            -->

            <a-form-item
              :label="$t('field.currentType')"
              :labelCol="{lg: {span: 8}, sm: {span: 8}}"
              :wrapperCol="{lg: {span: 8}, sm: {span: 17} }">
              <a-input
                :disabled="true"
                v-model="initParam.currentType"
                :placeholder="'请输入'+ this.$t('field.currentType')"/>
            </a-form-item>

            <a-form-item
              label="退款金额"
              :labelCol="{lg: {span: 8}, sm: {span: 8}}"
              :wrapperCol="{lg: {span: 8}, sm: {span: 17} }">
              <a-input
                :precision="0"
                @change="onChange"
                type="number"
                :min="0"
                v-model="initParam.serviceRefundAmount"
                placeholder="请输入退款金额"/>
            </a-form-item>
            <span style="font-size:14px;margin-left:10px"> 当前值：</span>
            <span style="font-size:14px;margin-left:15px">{{ initParam.formatRefAmount }}</span>
          </a-col>

        </a-row>
      </a-form>
    </div>

    <div v-if="initParam.paymentRefund" style="text-align: center;">
      <div v-if="payBillList.length === 0" >
        <span>未查询到渠道支付信息</span>
      </div>

      <div style="text-align:left;" v-for="(item, idx) in payBillList" bordered :key="idx">

        <a-descriptions :column="2" bordered size="small" v-if="item" title="">
          <a-descriptions-item label="支付流水">{{ item.payBillNo }}</a-descriptions-item>
          <a-descriptions-item label="支付渠道">{{ item.channelName }}</a-descriptions-item>
          <a-descriptions-item label="支付金额">{{ formatTypeMoney(item.payAmount, item.currentType,item.moneyMultiple) }}</a-descriptions-item>
          <a-descriptions-item label="支付状态">{{ item.payStatusDesc }}</a-descriptions-item>
        </a-descriptions>
        <br/>

      </div>

    </div>

    <a-divider/>
    <div style="text-align: right">
      <a-button @click="$emit('handleRefundCancel')" >取消</a-button>
      <a-button v-if="initParam.balanceRefund" style="margin-left: 10px" type="primary" @click="handleOrderRefund(serviceOrderData)" >确定</a-button>
      <a-button v-if="initParam.paymentRefund && allowPaymentRefund" style="margin-left: 10px" type="primary" @click="handleOrderRefund(serviceOrderData)" >原路退回</a-button>
    </div>

  </div>
</template>

<script>
import {
  qryPayBillList,
  serviceOrderRefund
} from '@/api/order/serviceOrder'

export default {
  name: 'ServiceOrderRefund',
  props: {
    serviceOrderData: {
      type: Object
    },
    initParam: {
      type: Object
    }
  },

  data () {
    return {
        codeList: {
         'currentTypeList': [
           { label: '美国美元', value: '美国美元' },
           { label: '墨西哥比索', value: '墨西哥比索' },
           { label: '巴西雷亚尔', value: '巴西雷亚尔' },
           { label: '阿根廷比索', value: '阿根廷比索' }
          ]
        },
        payBillList: [],
        formatRefAmount: '0',
        allowPaymentRefund: false
    }
  },
  methods: {

      handleBalanceRefund (record) {
        console.log('handleBalanceRefund', record)
        this.initParam.balanceRefund = true
        this.initParam.paymentRefund = false
      },

      handlePaymentRefund (record) {
        const that = this
        that.allowPaymentRefund = false
        console.log('handlePaymentRefund', record)
        this.initParam.balanceRefund = false
        this.initParam.paymentRefund = true
        const param = {}
        param.serviceOrderId = record.serviceOrderId
        param.serviceOrderNo = record.serviceOrderNo
        // param.saleOrderId = record.saleOrderId
        param.saleOrderNo = record.saleOrderNo
        return qryPayBillList(param)
        .then((res) => {
          console.log('qryPayBillList->PayBillList', param, res)
          that.payBillList = res.payBillList
          if (!res.refunded) {
            that.allowPaymentRefund = true
          }
          // that.$message.success("查询成功")
        }).catch(() => {
         that.$message.error('查询支付信息失败')
        })
      },

      handleOrderRefund (record) {
        let {serviceOrderId, serviceOrderNo, saleOrderId, saleOrderNo, currentType, moneyMultiple} = record
        console.log('handleOrderRefund', record)
        const param = {serviceOrderId, serviceOrderNo, saleOrderId, saleOrderNo, currentType, moneyMultiple}

        // console.log('handleRefund->refund', param)
        let content
        if (this.initParam.balanceRefund) {
          param.refundMethod = '余额退款'
          param.refundAmount = this.initParam.serviceRefundAmount
          if (param.refundAmount <= 0 || param.refundAmount === '') {
            this.$message.warning('退款金额必须大于0!')
            return
          }

          content = '[余额退款] 退款金额：' + this.formatTypeMoney(param.refundAmount, param.currentType, param.moneyMultiple) +
          '，服务单总金额：' + this.formatTypeMoney(record.dealAmount, param.currentType, param.moneyMultiple)
          if (record.dealAmount < param.refundAmount) {
            this.$message.warning('退款金额不能大于订单金额!')
            return
          }
        }
        if (this.initParam.paymentRefund) {
          param.refundMethod = '原路退回'
          content = '[原路退回] 原支付渠道全额退款'
        }
        const that = this
        this.$confirm({
          title: '确认提交退款请求?',
          content: content,
          onOk () {
            console.log('OK')
            that.handleServiceOrderRefund(param)
          },
          onCancel () {
            console.log('Cancel')
          }
        })
      },

      handleServiceOrderRefund (param) {
        const that = this
        serviceOrderRefund(param).then((res) => {
          console.log('handleRefund->refund', param, res)
          if (res.success) {
            // that.$message.success("退款成功")
            that.$emit('handleRefundOk')
          } else {
            that.$message.error('退款失败,失败原因：' + res.respMsg)
          }
        }).catch(() => {
          that.$message.error('退款失败')
        })
      },

      onChange () {
        let {serviceRefundAmount, currentType, moneyMultiple} = this.initParam
        this.initParam.formatRefAmount = this.formatTypeMoney(serviceRefundAmount, currentType, moneyMultiple)      }

  }
}

</script>
<style>

</style>
