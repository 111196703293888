<template>
  <div class="table-page-search-wrapper">
    <a-form layout="inline">
      <a-row :gutter="24">
        <a-col :md="8" :sm="24">
          <a-form-item
            :label="$t('field.serviceOrderNo')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-input
              v-decorator="[
                'queryParam.serviceOrderNo',
                {
                  rules: [
                    { required: true, message: '请输入' + this.$t('field.serviceOrderNo') },
                    { max: 100, message: this.$t('field.serviceOrderNo') + '长度小于等于100' }
                  ]
                }
              ]"
              name="queryParam.serviceOrderNo"
              v-model="queryParam.serviceOrderNo"
              :placeholder="'请输入' + this.$t('field.serviceOrderNo')"
            />
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24">
          <a-form-item
            :label="$t('field.saleOrderNo')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-input
              v-decorator="[
                'queryParam.saleOrderNo',
                {
                  rules: [
                    { required: true, message: '请输入' + this.$t('field.saleOrderNo') },
                    { max: 100, message: this.$t('field.saleOrderNo') + '长度小于等于100' }
                  ]
                }
              ]"
              name="queryParam.saleOrderNo"
              v-model="queryParam.saleOrderNo"
              :placeholder="'请输入' + this.$t('field.saleOrderNo')"
            />
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24">
          <a-form-item
            :label="$t('field.serviceOrderStatus')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-select
              :allowClear="true"
              mode="multiple"
              v-model="queryParam.serviceOrderStatus"
              :placeholder="'请输入' + this.$t('field.serviceOrderStatus')"
            >
              <a-select-option v-for="(item, index) in codeList.serviceOrderStatus" :key="index" :value="item.value"
                >{{ item.label }}
              </a-select-option>
            </a-select></a-form-item
          >
        </a-col>
        <a-col :md="8" :sm="24">
          <a-form-item
            v-if="showAdvancedSearchCondition"
            :label="$t('field.contactPhone')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-input
              v-decorator="[
                'queryParam.contactPhone',
                {
                  rules: [
                    { required: true, message: '请输入' + this.$t('field.contactPhone') },
                    { max: 100, message: this.$t('field.contactPhone') + '长度小于等于100' }
                  ]
                }
              ]"
              name="queryParam.contactPhone"
              v-model="queryParam.contactPhone"
              :placeholder="'请输入' + this.$t('field.contactPhone')"
            />
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24">
          <a-form-item
            v-if="showAdvancedSearchCondition"
            :label="$t('field.contactEmail')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-input
              v-decorator="[
                'queryParam.contactEmail',
                {
                  rules: [
                    { required: true, message: '请输入' + this.$t('field.contactEmail') },
                    { max: 100, message: this.$t('field.contactEmail') + '长度小于等于100' }
                  ]
                }
              ]"
              name="queryParam.contactEmail"
              v-model="queryParam.contactEmail"
              :placeholder="'请输入' + this.$t('field.contactEmail')"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="24" :sm="24">
          <span
            class="table-page-search-submitButtons"
            :style="(showAdvancedSearchCondition && { overflow: 'hidden' }) || {}"
          >
            <a-button
              style="margin-left: 20px"
              icon="search"
              type="primary"
              @click="$parent.$parent.$refs.table.refresh(true)"
              >{{ $t('button.search') }}</a-button
            >
            <a-divider type="vertical" />
            <a-button
              style="margin-left: 10px"
              @click="
                () => {
                  $parent.$parent.queryParam = {}
                  $nextTick(() => {
                    $parent.$parent.$refs.table.refresh(true)
                  })
                }
              "
              >{{ $t('button.reset') }}</a-button
            >
            <a @click="toggleAdvancedSearch" style="margin-left: 10px">
              {{ showAdvancedSearchCondition ? $t('button.collapseIn') : $t('button.collapseOut') }}
              <a-icon :type="showAdvancedSearchCondition ? 'up' : 'down'" />
            </a>
          </span>
        </a-col>
      </a-row>

      <a-divider />
      <a-row>
        <a-col :md="24" :sm="24">
          <span class="table-page-search-submitButtons">
            <a-button size="large" style="margin-left: 20px" @click="qryByOrderStatus('待确认')"
              >待确认({{ totalNum.waitDecided }})</a-button
            >
            <a-divider type="vertical" />
            <a-button size="large" style="margin-left: 20px" @click="qryByOrderStatus('待处理')"
              >待处理({{ totalNum.waitPending }})</a-button
            >
            <a-divider type="vertical" />
            <a-button size="large" style="margin-left: 20px" @click="qryByOrderStatus('已完成')">已完成</a-button>
            <a-divider type="vertical" />
            <a-button size="large" style="margin-left: 20px" @click="qryByOrderStatus('已取消')">已取消</a-button>
            <a-divider type="vertical" />
          </span>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  name: 'ServiceOrderSearch',
  components: {},
  props: {
    totalNum: {
      type: Object
    },
    // 查询参数
    queryParam: {
      type: Object
    }
  },
  data() {
    return {
      showAdvancedSearchCondition: false,
      codeList: {
        serviceOrderStatus: [
          { label: '待确认', value: '待确认' },
          { label: '待处理', value: '待处理' },
          { label: '已完成', value: '已完成' },
          { label: '已取消', value: '已取消' }
        ]
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    toggleAdvancedSearch() {
      this.showAdvancedSearchCondition = !this.showAdvancedSearchCondition
    },
    qryByOrderStatus(param) {
      const serviceStatus = []
      serviceStatus.push(param)
      this.queryParam.serviceOrderStatus = serviceStatus
      this.$parent.$parent.$refs.table.refresh(true)
    },
    resetSearchForm() {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
