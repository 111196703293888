import request from '@/utils/request'
import qs from 'qs'

const baseUrl = '/api'

export function saveServiceOrderData (parameter) {
  let url = baseUrl + '/order/serviceOrder/save'
  if (!parameter.serviceOrderId) {
    url = baseUrl + '/order/serviceOrder/create'
  }
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function searchServiceOrder (parameter) {
  return request({
    url: baseUrl + '/order/serviceOrder/search?' + qs.stringify(parameter, { indices: false }),
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data.list,
      pageNo: res.data.pageInfo.pageNo ? res.data.pageInfo.pageNo : 1,
      totalCount: res.data.pageInfo.totalCount
    }
  })
}

export function listServiceOrder (parameter) {
  return request({
    url: baseUrl + '/order/serviceOrder/list',
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data.list
    }
  })
}

export function approvalServiceOrder (parameter) {
  return request({
    url: baseUrl + '/order/serviceOrder/approval',
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data.list
    }
  })
}
export function getServiceOrderData (parameter) {
  return request({
    url: baseUrl + '/order/serviceOrder/get',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function enableServiceOrder (parameter) {
  return request({
    url: baseUrl + '/order/serviceOrder/enable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function disableServiceOrder (parameter) {
  return request({
    url: baseUrl + '/order/serviceOrder/disable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function closeServiceOrder (parameter) {
  const url = baseUrl + '/order/serviceOrder/close'
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function deleteServiceOrder (parameter) {
  const url = baseUrl + '/order/serviceOrder/delete'
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function getServiceOrderDetail (parameter) {
  return request({
    url: baseUrl + '/order/serviceOrder/getDetail', // getDetail
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function saveServiceOrderServiceOrderItemData (parameter) {
  let url = baseUrl + '/order/serviceOrder/save'
  if (!parameter.serviceOrderId) {
    url = baseUrl + '/order/serviceOrder/create'
  }
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function addRemark (parameter) {
  return request({
    url: baseUrl + '/order/serviceOrder/addRemark',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function changeStatus (parameter) {
  return request({
    url: baseUrl + '/order/serviceOrder/changeStatus',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function getServiceOrderCount (parameter) {
  return request({
    url: baseUrl + '/order/serviceOrder/countServiceOrder',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function qryPayBillList (parameter) {
  return request({
    url: baseUrl + '/order/serviceOrder/payBillList',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function serviceOrderRefund (parameter) {
  return request({
    url: baseUrl + '/order/serviceOrder/refund',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}
