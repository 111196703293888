<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <a-descriptions bordered title="">
        <a-descriptions-item label="售后单号">{{ data.serviceOrderNo }}</a-descriptions-item>
        <a-descriptions-item label="售后单提交时间">{{ formatDateTime(data.serviceOrderSubmitTime) }}</a-descriptions-item>
        <a-descriptions-item label="售后单状态">{{ data.serviceOrderStatus }}</a-descriptions-item>
        <a-descriptions-item label="申请内容">{{ data.message }}</a-descriptions-item>
      </a-descriptions>
      <br />

      <a-divider style="margin-bottom: 32px"/>

      <a-steps style="width:80%;margin-left:10%" v-if="data.totalStep==3" :current="data.step" :status="data.stepStatus" >
        <a-step>
          <span slot="title">待确认</span>
          <span slot="description">{{ formatDateTime(data.midStatusOprTime) }} {{ data.midStatusOprName }}</span>
        </a-step>
        <a-step>
          <span slot="title">待处理</span>
          <span slot="description">{{ formatDateTime(data.finalStatusOprTime) }} {{ data.finalStatusOprName }}</span>
        </a-step>
        <a-step>
          <span slot="title">{{ data.finalStatusCompleted ?data.serviceOrderStatus:'已完成/已取消 ' }}</span>
          <span slot="description">{{ data.statusRemark }}</span>
        </a-step>
      </a-steps>
      <a-steps style="width:80%;margin-left:10%" v-if="data.totalStep==2" :current="data.step" :status="data.stepStatus" >
        <a-step>
          <span slot="title">待确认</span>
          <span slot="description">{{ formatDateTime(data.finalStatusOprTime) }} {{ data.finalStatusOprName }}</span>
        </a-step>
        <a-step>
          <span slot="title">{{ data.serviceOrderStatus }}</span>
          <span slot="description">{{ data.statusRemark }}</span>
        </a-step>
      </a-steps>
      <br /><br />

      <div v-if="data && data.serviceOrderItemList" class="title">售后商品明细</div>
      <a-table
        v-if="data && data.serviceOrderItemList"
        :columns="serviceOrderItemListColumns"
        :dataSource="data.serviceOrderItemList"
        :bordered="true"
        :row-key="(record, index) => index"
        :pagination="false">
        <div slot="money" slot-scope="text">
          {{ formatTypeMoney(text, data.currentType,data.moneyMultiple) }}
        </div>
        <span slot="saleAttrList" slot-scope="text, record">
          <a-tag v-for="(item, idx) in record.saleAttrList" :key="idx" color="blue">
            [{{ item.attrName }}]-[{{ item.valueName }}]
          </a-tag><br/>
        </span>
      </a-table>
      <br />
      <br />

      <a-descriptions title="同订单售后单信息">
        <a-descriptions-item label="订单号">
          <a @click="handleSaleDetail(data)">
            {{ data.saleOrderNo }}
          </a>
        </a-descriptions-item>
        <a-descriptions-item label="订单种类">{{ this.getCode('isSlaveOrder', data.isSlaveOrder) }}</a-descriptions-item>
        <a-descriptions-item label="订单状态">{{ this.getCode('saleOrderStatus', data.saleOrderStatus) }}</a-descriptions-item>
      </a-descriptions>
      <a-table
        v-if="data && data.sameOrderServiceList"
        :columns="sameOrderServiceListColumns"
        :dataSource="data.sameOrderServiceList"
        :bordered="true"
        :row-key="(record, index) => index"
        :pagination="false">
        <div slot="orderStyle" slot-scope="text">
          <span style="font-weight:bold" v-if="text===data.serviceOrderNo">{{ text }}</span>
          <span v-if="text!==data.serviceOrderNo">{{ text }}</span>
        </div>
        <div slot="datetime" slot-scope="text">
          {{ formatDateTime(text) }}
        </div>
        <template slot="action" slot-scope="text, record">
          <a v-if="record.serviceOrderNo!==data.serviceOrderNo" @click="handleDetail(record)">{{ $t("button.detail") }}</a>
        </template>
      </a-table>
      <br />

      <a-descriptions bordered title="会员信息">
        <a-descriptions-item v-if="data.memberInfo" label="发起用户">{{ data.memberInfo.memberNickName }}</a-descriptions-item>
        <a-descriptions-item v-if="data.memberInfo" label="用户ID">{{ data.memberInfo.memberId }}</a-descriptions-item>

        <a-descriptions-item v-if="!data.memberInfo" label="发起用户"></a-descriptions-item>
        <a-descriptions-item v-if="!data.memberInfo" label="用户ID"></a-descriptions-item>

        <a-descriptions-item label="联系人">{{ data.contactName }}</a-descriptions-item>
        <a-descriptions-item label="联系人电话">{{ data.contactPhone }}</a-descriptions-item>
        <a-descriptions-item label="联系人邮箱">{{ data.contactEmail }}</a-descriptions-item>
      </a-descriptions>
      <br />

      <a-divider style="margin-bottom: 32px"/>
      <div v-if="data" class="title">备注信息</div>
      <a-table
        v-if="data && data.remarkList"
        :columns="remarkListColumns"
        :dataSource="data.remarkList"
        :bordered="true"
        :row-key="(record, index) => index"
        :pagination="false">
        <div slot="datetime" slot-scope="text">
          {{ formatDateTime(text) }}
        </div>
        <div slot="message" slot-scope="text">
          <span>{{ text }}</span>
        </div>
      </a-table>
      <br />

      <a-form-item>
        <a-textarea
          style="width:60%;height:120px"
          :placeholder="'请输入备注信息'"
          :maxLength="300"
          v-model="remarkComment"
        />
        <br />
        <a-popconfirm title="确认发表备注信息?" @confirm="() => handleAddRemark()">
          <a-button htmlType="button" type="primary">发表备注</a-button>
        </a-popconfirm>

      </a-form-item>
      <a-divider style="margin-bottom: 32px"/>
      <div style="text-align: center">
        <a-button v-if="data.serviceOrderStatus=='待处理'" htmlType="button" type="primary" @click="handleServiceRefund(data)">退款</a-button>
        <a-popconfirm title="确认设置状态为[待处理]?" @confirm="() => handlePending(data)">
          <a-button v-if="data.serviceOrderStatus=='待确认'" style="margin-left: 20px" htmlType="button" type="primary">设置为【已确认、待处理】</a-button>
        </a-popconfirm>
        <a-popconfirm title="确认设置状态为[已取消]?" @confirm="() => handleCanceled(data)">
          <a-button v-if="data.serviceOrderStatus=='待确认' || data.serviceOrderStatus=='待处理'" style="margin-left: 20px" htmlType="button" type="primary">设置为【已取消】</a-button>
        </a-popconfirm>
        <a-popconfirm title="确认设置状态为[已处理]?" @confirm="() => handleDecided(data)">
          <a-button v-if="data.serviceOrderStatus=='待处理'" style="margin-left: 20px" htmlType="button" type="primary" >设置为【已处理，售后单已完成】</a-button>
        </a-popconfirm>
      </div>

    </a-card>
    <CancelOprate ref="cancelOprate" @handleOk="handleCanceledEditOk"/>
    <CommentEditor ref="commentEditor" @handleOk="handleCommentOk"/>

    <a-modal
      width="50%"
      :title="$t('page.refund')"
      :visible="serviceOrderRefundShow"
      @ok="handleRefundOk"
      @cancel="handleRefundCancel"
      footer=""
    >
      <ServiceOrderRefund
        ref="serviceOrderRefund"
        :serviceOrderData="data"
        :initParam="refundParam"
        @handleRefundOk="handleRefundOk"
        @handleRefundCancel="handleRefundCancel" />
    </a-modal>

    <!-- <a-modal
      :closable="true"
      :visible="showOutDetailDrawer"
      width="80%"
      footer=""
      @cancel="onOutDetailDrawerClose">
      <ServiceOrderDetail ref="memberDetail" :options="detailOutOptions" :showDetailDrawer="showOutDetailDrawer"></ServiceOrderDetail>
    </a-modal> -->

    <!-- <a-modal
      :closable="true"
      :visible="outShowDetailDrawer"
      width="80%"
      footer=""
      @cancel="onOutDetailDrawerClose">
      <SaleOrderDetail ref="memberDetail" :options="outDetailOptions" :showDetailDrawer="outShowDetailDrawer"></SaleOrderDetail>
    </a-modal> -->
  </page-header-wrapper>

</template>

<script>
import { STable } from '@/components'
import {
  getServiceOrderDetail,
  addRemark,
  changeStatus
} from '@/api/order/serviceOrder'
import ServiceOrderRefund from '@/views/order/components/ServiceOrderRefund'
import CommentEditor from '@/components/comment/CommentEditor'
import CancelOprate from '@/components/comment/CancelOprate'
// import ServiceOrderDetail from '@/views/order/ServiceOrderDetail'
// import SaleOrderDetail from '@/views/order/SaleOrderDetail'

export default {
  name: 'ServiceOrderDetail',
  components: {
    STable, ServiceOrderRefund, CommentEditor, CancelOprate,
    // SaleOrderDetail,
    // ServiceOrderDetail, 
  },
  props: {
    options: {
      type: Object
    },
    showDetailDrawer: {
      type: Boolean
    }
  },
  created () {
    // getRoleList({t: new Date()})
  },
  watch: {
    showDetailDrawer (val, oldVal) {
      console.log('showDetailDrawer => ',val)
      if (val) {
        this.loadData({})
      }
    }
  },
  mounted () {
    this.loadData({})
  },
  data () {
    return {
      'showOutDetailDrawer': false,
      'outShowDetailDrawer': false,
      'detailOutOptions': {},
      'outDetailOptions': {},
      'serviceOrderRefundShow': false,
      'reqParam': {},
      'data': {},
      'refundParam': {},
      'remarkComment': '',
      'sameOrderServiceListColumns': [
        {
          'defaultTitle': '售后单号',
          'title': this.$t('columnName.serviceOrderNo'),
          'dataIndex': 'serviceOrderNo',
          'key': 'serviceOrderNo',
          'align': 'left',
          scopedSlots: {
            customRender: 'orderStyle'
          }
        },
        {
          'defaultTitle': '申请时间',
          'title': this.$t('columnName.serviceOrderSubmitTime'),
          'dataIndex': 'serviceOrderSubmitTime',
          'key': 'serviceOrderSubmitTime',
          'align': 'left',
          scopedSlots: {
            customRender: 'datetime'
          }
        },
        {
          'defaultTitle': '售后单状态',
          'title': this.$t('columnName.serviceOrderStatus'),
          'dataIndex': 'serviceOrderStatus',
          'key': 'serviceOrderStatus',
          'align': 'center'
          // customRender: (text) => (this.getCode('serviceOrderStatus', text))
        },
        {
          'defaultTitle': '申请内容',
          'title': this.$t('columnName.message'),
          'dataIndex': 'message',
          'key': 'message',
          'align': 'left'

        },
        {
          'defaultTitle': '操作',
          'title': '操作',
          'dataIndex': 'action',
          'key': 'action',
          'align': 'left',
          'scopedSlots': {
            'customRender': 'action'
          }
        }
      ],
      'serviceOrderItemListColumns': [
        {
          'defaultTitle': 'SKU编号',
          'title': this.$t('columnName.skuNo'),
          'dataIndex': 'skuNo',
          'key': 'skuNo',
          'align': 'left'
        },
        {
          'defaultTitle': 'SKU名称',
          'title': this.$t('columnName.skuName'),
          'dataIndex': 'skuName',
          'key': 'skuName',
          'align': 'left'
        },
        {
          'defaultTitle': '数量',
          'title': this.$t('columnName.quantity'),
          'dataIndex': 'quantity',
          'key': 'quantity',
          'align': 'center'
        },
        {
          'defaultTitle': '交易价格',
          'title': this.$t('columnName.dealPrice'),
          'dataIndex': 'dealPrice',
          'key': 'dealPrice',
          'align': 'center',
          scopedSlots: {
            customRender: 'money'
          }
        },
        {
          'defaultTitle': '标牌价格',
          'title': this.$t('columnName.tagPrice'),
          'dataIndex': 'tagPrice',
          'key': 'tagPrice',
          'align': 'center',
          scopedSlots: {
            customRender: 'money'
          }
        },
        /*{
          'defaultTitle': '订单金额',
          'title': this.$t('columnName.dealAmount'),
          'dataIndex': 'dealAmount',
          'key': 'dealAmount',
          'align': 'center',
          scopedSlots: {
            customRender: 'money'
          }
        },*/
        {
          'defaultTitle': '销售属性列表',
          'title': this.$t('columnName.saleAttrList'),
          'dataIndex': 'saleAttrList',
          'key': 'saleAttrList',
          'align': 'left',
          scopedSlots: {
            customRender: 'saleAttrList'
          }
        }
      ],
      'remarkListColumns': [

        {
          'defaultTitle': '序号',
          'title': this.$t('columnName.remarkNo'),
          'dataIndex': 'no',
          'key': 'no',
          'align': 'left',
           defaultSortOrder: 'ascend',
           sorter: (a, b) => {
              console.log(a, b)
              return a.remarkNo > b.remarkNo ? 1 : -1
          }
        },
        {
          'defaultTitle': '备注时间',
          'title': this.$t('columnName.remarkTime'),
          'dataIndex': 'remarkTime',
          'key': 'remarkTime',
          'align': 'left',
          scopedSlots: {
            customRender: 'datetime'
          }

        },
        {
          'defaultTitle': '备注人',
          'title': this.$t('columnName.remarkName'),
          'dataIndex': 'userInfo.name',
          'key': 'userInfo.name',
          'align': 'left'

        },
        {
          'defaultTitle': '备注内容',
          'title': this.$t('columnName.message'),
          'dataIndex': 'content',
          'key': 'content',
          'align': 'left'
        }
      ]

    }
  },
  methods: {
    // 加载数据方法 必须为 Promise 对象
    loadData (parameter) {
      getServiceOrderDetail(Object.assign(parameter, {
        // serviceOrderId: (parameter.serviceOrderId || this.options.serviceOrderId),
        serviceOrderNo: (parameter.serviceOrderNo || this.options.serviceOrderNo)
         }))
        .then(res => {
          console.log('loadData->getServiceOrderDetail.parameter', parameter, res)
          res.totalStep = 2
          if (res.serviceOrderStatus === '已取消' || res.serviceOrderStatus === '已完成') {
              res.step = 2
              res.stepStatus = 'finish'
          } else {
            res.totalStep = 3
            res.stepStatus = 'process'
            if (res.serviceOrderStatus === '待处理') {
              res.step = 1
            } else {
              res.step = 0
            }
          }
          // 循环状态变更记录
          res.statusChangeInfoList.forEach(element => {
            // console.log("statusChangeInfoList--element",element)
            if (element.serviceOrderStatus === '待处理') {
              res.totalStep = 3
              res.midStatusOprName = element.creatorName
              res.midStatusOprTime = element.createTime
            }
            if (res.serviceOrderStatus === '已取消' || res.serviceOrderStatus === '已完成') {
              res.finalStatusOprName = element.creatorName
              res.finalStatusOprTime = element.createTime
              res.statusRemark = element.remark
            }
          })

          this.data = res // this.data = res.data
          return this.data
        })
    },

    handleDetail (record) {//切换不同售后单号的售后单详情 新开页面带参
      console.log('detail record', record)
      let { serviceOrderId, serviceOrderNo } = record;
      let {href} = this.$router.resolve({
        path: '/order/ServiceOrderList',
        query: { serviceOrderId, serviceOrderNo }
      })
      window.open(href, '_blank')
      // this.detailOutOptions = {
      //   serviceOrderId: record.serviceOrderId,
      //   serviceOrderNo: record.serviceOrderNo
      // }
      // this.showOutDetailDrawer = true
    },

    handleSaleDetail (record) {
      console.log('saleDetail record', record)
      let {saleOrderId, saleOrderNo} = record;
      let {href} = this.$router.resolve({
        path: '/order/SaleOrderList',
        query: { saleOrderId, saleOrderNo }
      })
      window.open(href, '_blank')
      // this.outDetailOptions = {
      //   saleOrderNo: record
      // }
      // this.outShowDetailDrawer = true
    },

    handlePending (record) {
      const that = this
      record.serviceChangeStatus = '待处理'
      record.serviceChangeStatusDesc = '待处理'
      //this.$refs['commentEditor'].show(record, '服务状态变更')
      record.common = ''
      let params={
        record: record
      }
      that.handleCommentOk(params)
    },

    handleDecided (record) {
      const that = this
      record.serviceChangeStatus = '已完成'
      record.serviceChangeStatusDesc = '已完成'
      //this.$refs['commentEditor'].show(record, '服务状态变更')
      record.common = ''
      let params={
        record: record
      }
      that.handleCommentOk(params)
    },

    handleCommentOk (params) {
      const that = this
      const record = params.record
      console.log('handleCommentOk', record)
      const reqParam = {}
      reqParam.serviceOrderStatus = record.serviceChangeStatus
      reqParam.serviceOrderStatusDesc = record.serviceChangeStatusDesc
      reqParam.serviceOrderId = record.serviceOrderId
      reqParam.serviceOrderNo = record.serviceOrderNo
      reqParam.remarkComment = record.comment
      console.log('handleCommentOk', record, reqParam)
      return changeStatus(reqParam)
        .then((res) => {
          console.log('changeStatus->changeStatus', reqParam, res)
          // this.$message.success(this.$t('entity.saleOrder.enable.success'))
          that.options.serviceOrderId = record.serviceOrderId
          that.loadData({})
          that.$message.success('提交成功')
        }).catch(() => {
         that.$refs.table.refresh(true)
         that.$message.error('提交失败')
        })
    },

    handleCanceled (record) {
      const that = this
      record.serviceChangeStatus = '已取消'
      record.serviceChangeStatusDesc = '已取消'
      this.$refs['cancelOprate'].show(record)
    },

    handleCanceledEditOk (params) {
      const record = params.record
      const that = this
      console.log('handleCanceledEditOk', record)
      const reqParam = {}
      reqParam.serviceOrderStatus = record.serviceChangeStatus
      reqParam.serviceOrderStatusDesc = record.serviceChangeStatusDesc
      reqParam.serviceOrderId = record.serviceOrderId
      reqParam.serviceOrderNo = record.serviceOrderNo
      reqParam.remarkComment = record.remarkComment
      console.log('handleCanceledEditOk', record, reqParam)
      return changeStatus(reqParam)
        .then((res) => {
          console.log('changeStatus->changeStatus', reqParam, res)
          // this.$message.success(this.$t('entity.saleOrder.enable.success'))
          that.loadData({})

          that.$message.success('提交成功')
        }).catch(() => {
         that.$refs.table.refresh(true)
         that.$message.error('提交失败')
        })
    },

    handleAddRemark () {
      if (this.remarkComment.trim() === '') {
        this.$message.warning('请输入备注信息！')
        return
      }
      this.reqParam.remarkComment = this.remarkComment
      this.reqParam.serviceOrderId = this.data.serviceOrderId
      this.reqParam.serviceOrderNo = this.data.serviceOrderNo
      console.log('detail reqParam', this.reqParam)
      return addRemark(this.reqParam)
        .then((res) => {
          console.log('addRemark->addRemark', this.reqParam, res)
          // this.$message.success(this.$t('entity.saleOrder.enable.success'))
          this.data.remarkList = res.result.remarkList
          this.$message.success('提交成功')
          this.remarkComment = ''
          return res
        })
        .catch(() => {
          // this.$message.error(this.$t('entity.saleOrder.enable.fail'))
          this.$message.error('提交失败')
        })
    },

    handleServiceRefund (record) {
      console.log('handleServiceRefund', record)
      this.refundParam = {
        balanceRefund: true,
        paymentRefund: false,
        serviceRefundAmount: 0,
        currentType: record.currentType,
        moneyMultiple: record.moneyMultiple
      }
      this.refundParam.formatRefAmount = this.formatTypeMoney(0, record.currentType, record.moneyMultiple)
      this.serviceOrderRefundShow = true
    },

    handleRefundOk (record) {
      console.log('handleRefundOk', record)
      this.$message.success('退款提交成功')
      this.serviceOrderRefundShow = false
    },

    handleRefundCancel (record) {
      console.log('handleRefundCancel', record)
      this.serviceOrderRefundShow = false
    },

    onOutDetailDrawerClose () {
      this.showOutDetailDrawer = false
    },

    onOutDetailDrawerClose () {
      this.outShowDetailDrawer = false
    }
  },
  filters: {
    statusFilter (status) {
      const statusMap = {
        'processing': '进行中',
        'success': '完成',
        'failed': '失败'
      }
      return statusMap[status]
    }
  },
  computed: {
    title () {
      return this.$route.meta.title
    }
  }

}
</script>

<style lang="less" scoped>
.title {
  color: rgba(0, 0, 0, .85);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}
</style>
