var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-card',{attrs:{"body-style":{padding: '24px 32px'},"bordered":false}},[_c('a-form-item',{attrs:{"label":_vm.$t('field.serviceOrderNo'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.serviceOrderNo',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.serviceOrderNo') },
                         { max: 100, message: this.$t('field.serviceOrderNo')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.serviceOrderNo',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.serviceOrderNo') },\n                         { max: 100, message: this.$t('field.serviceOrderNo')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.serviceOrderNo","placeholder":'请输入'+ this.$t('field.serviceOrderNo')},model:{value:(_vm.serviceOrderData.serviceOrderNo),callback:function ($$v) {_vm.$set(_vm.serviceOrderData, "serviceOrderNo", $$v)},expression:"serviceOrderData.serviceOrderNo"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.shippingBillNo'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.shippingBillNo',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.shippingBillNo') },
                         { max: 100, message: this.$t('field.shippingBillNo')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.shippingBillNo',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.shippingBillNo') },\n                         { max: 100, message: this.$t('field.shippingBillNo')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.shippingBillNo","placeholder":'请输入'+ this.$t('field.shippingBillNo')},model:{value:(_vm.serviceOrderData.shippingBillNo),callback:function ($$v) {_vm.$set(_vm.serviceOrderData, "shippingBillNo", $$v)},expression:"serviceOrderData.shippingBillNo"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.serviceOrderStatus'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.serviceOrderStatus',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.serviceOrderStatus') },
                         { max: 100, message: this.$t('field.serviceOrderStatus')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.serviceOrderStatus',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.serviceOrderStatus') },\n                         { max: 100, message: this.$t('field.serviceOrderStatus')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.serviceOrderStatus","placeholder":'请输入'+ this.$t('field.serviceOrderStatus')},model:{value:(_vm.serviceOrderData.serviceOrderStatus),callback:function ($$v) {_vm.$set(_vm.serviceOrderData, "serviceOrderStatus", $$v)},expression:"serviceOrderData.serviceOrderStatus"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.serviceOrderStatusDesc'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.serviceOrderStatusDesc',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.serviceOrderStatusDesc') },
                         { max: 100, message: this.$t('field.serviceOrderStatusDesc')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.serviceOrderStatusDesc',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.serviceOrderStatusDesc') },\n                         { max: 100, message: this.$t('field.serviceOrderStatusDesc')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.serviceOrderStatusDesc","placeholder":'请输入'+ this.$t('field.serviceOrderStatusDesc')},model:{value:(_vm.serviceOrderData.serviceOrderStatusDesc),callback:function ($$v) {_vm.$set(_vm.serviceOrderData, "serviceOrderStatusDesc", $$v)},expression:"serviceOrderData.serviceOrderStatusDesc"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.activityName'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.activityName',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.activityName') },
                         { max: 100, message: this.$t('field.activityName')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.activityName',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.activityName') },\n                         { max: 100, message: this.$t('field.activityName')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.activityName","placeholder":'请输入'+ this.$t('field.activityName')},model:{value:(_vm.serviceOrderData.activityName),callback:function ($$v) {_vm.$set(_vm.serviceOrderData, "activityName", $$v)},expression:"serviceOrderData.activityName"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.serviceOrderSubmitTime'),"labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_vm._v(" "+_vm._s(_vm.formatTime(_vm.serviceOrderData.serviceOrderSubmitTime))+" ")]),_c('a-form-item',{attrs:{"label":_vm.$t('field.memberNickName'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.memberInfo.memberNickName',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.memberNickName') },
                         { max: 100, message: this.$t('field.memberNickName')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.memberInfo.memberNickName',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.memberNickName') },\n                         { max: 100, message: this.$t('field.memberNickName')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.memberInfo.memberNickName","placeholder":'请输入'+ this.$t('field.memberNickName')},model:{value:(_vm.serviceOrderData.memberInfo.memberNickName),callback:function ($$v) {_vm.$set(_vm.serviceOrderData.memberInfo, "memberNickName", $$v)},expression:"serviceOrderData.memberInfo.memberNickName"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.memberAvatarUrl'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.memberInfo.memberAvatarUrl',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.memberAvatarUrl') },
                         { max: 100, message: this.$t('field.memberAvatarUrl')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.memberInfo.memberAvatarUrl',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.memberAvatarUrl') },\n                         { max: 100, message: this.$t('field.memberAvatarUrl')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.memberInfo.memberAvatarUrl","placeholder":'请输入'+ this.$t('field.memberAvatarUrl')},model:{value:(_vm.serviceOrderData.memberInfo.memberAvatarUrl),callback:function ($$v) {_vm.$set(_vm.serviceOrderData.memberInfo, "memberAvatarUrl", $$v)},expression:"serviceOrderData.memberInfo.memberAvatarUrl"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.dealAmount'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.dealAmount',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.dealAmount') },
                         { max: 100, message: this.$t('field.dealAmount')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.dealAmount',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.dealAmount') },\n                         { max: 100, message: this.$t('field.dealAmount')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.dealAmount","placeholder":'请输入'+ this.$t('field.dealAmount')},model:{value:(_vm.serviceOrderData.dealAmount),callback:function ($$v) {_vm.$set(_vm.serviceOrderData, "dealAmount", $$v)},expression:"serviceOrderData.dealAmount"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.totalAmount'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.totalAmount',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.totalAmount') },
                         { max: 100, message: this.$t('field.totalAmount')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.totalAmount',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.totalAmount') },\n                         { max: 100, message: this.$t('field.totalAmount')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.totalAmount","placeholder":'请输入'+ this.$t('field.totalAmount')},model:{value:(_vm.serviceOrderData.totalAmount),callback:function ($$v) {_vm.$set(_vm.serviceOrderData, "totalAmount", $$v)},expression:"serviceOrderData.totalAmount"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.discountAmount'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.discountAmount',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.discountAmount') },
                         { max: 100, message: this.$t('field.discountAmount')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.discountAmount',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.discountAmount') },\n                         { max: 100, message: this.$t('field.discountAmount')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.discountAmount","placeholder":'请输入'+ this.$t('field.discountAmount')},model:{value:(_vm.serviceOrderData.discountAmount),callback:function ($$v) {_vm.$set(_vm.serviceOrderData, "discountAmount", $$v)},expression:"serviceOrderData.discountAmount"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.payAmount'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.payAmount',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.payAmount') },
                         { max: 100, message: this.$t('field.payAmount')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.payAmount',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.payAmount') },\n                         { max: 100, message: this.$t('field.payAmount')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.payAmount","placeholder":'请输入'+ this.$t('field.payAmount')},model:{value:(_vm.serviceOrderData.payAmount),callback:function ($$v) {_vm.$set(_vm.serviceOrderData, "payAmount", $$v)},expression:"serviceOrderData.payAmount"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.shippingFee'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.shippingFee',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.shippingFee') },
                         { max: 100, message: this.$t('field.shippingFee')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.shippingFee',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.shippingFee') },\n                         { max: 100, message: this.$t('field.shippingFee')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.shippingFee","placeholder":'请输入'+ this.$t('field.shippingFee')},model:{value:(_vm.serviceOrderData.shippingFee),callback:function ($$v) {_vm.$set(_vm.serviceOrderData, "shippingFee", $$v)},expression:"serviceOrderData.shippingFee"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.dealAmountShow'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.dealAmountShow',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.dealAmountShow') },
                         { max: 100, message: this.$t('field.dealAmountShow')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.dealAmountShow',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.dealAmountShow') },\n                         { max: 100, message: this.$t('field.dealAmountShow')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.dealAmountShow","placeholder":'请输入'+ this.$t('field.dealAmountShow')},model:{value:(_vm.serviceOrderData.dealAmountShow),callback:function ($$v) {_vm.$set(_vm.serviceOrderData, "dealAmountShow", $$v)},expression:"serviceOrderData.dealAmountShow"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.totalAmountShow'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.totalAmountShow',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.totalAmountShow') },
                         { max: 100, message: this.$t('field.totalAmountShow')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.totalAmountShow',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.totalAmountShow') },\n                         { max: 100, message: this.$t('field.totalAmountShow')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.totalAmountShow","placeholder":'请输入'+ this.$t('field.totalAmountShow')},model:{value:(_vm.serviceOrderData.totalAmountShow),callback:function ($$v) {_vm.$set(_vm.serviceOrderData, "totalAmountShow", $$v)},expression:"serviceOrderData.totalAmountShow"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.discountAmountShow'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.discountAmountShow',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.discountAmountShow') },
                         { max: 100, message: this.$t('field.discountAmountShow')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.discountAmountShow',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.discountAmountShow') },\n                         { max: 100, message: this.$t('field.discountAmountShow')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.discountAmountShow","placeholder":'请输入'+ this.$t('field.discountAmountShow')},model:{value:(_vm.serviceOrderData.discountAmountShow),callback:function ($$v) {_vm.$set(_vm.serviceOrderData, "discountAmountShow", $$v)},expression:"serviceOrderData.discountAmountShow"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.payAmountShow'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.payAmountShow',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.payAmountShow') },
                         { max: 100, message: this.$t('field.payAmountShow')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.payAmountShow',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.payAmountShow') },\n                         { max: 100, message: this.$t('field.payAmountShow')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.payAmountShow","placeholder":'请输入'+ this.$t('field.payAmountShow')},model:{value:(_vm.serviceOrderData.payAmountShow),callback:function ($$v) {_vm.$set(_vm.serviceOrderData, "payAmountShow", $$v)},expression:"serviceOrderData.payAmountShow"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.shippingFeeShow'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.shippingFeeShow',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.shippingFeeShow') },
                         { max: 100, message: this.$t('field.shippingFeeShow')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.shippingFeeShow',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.shippingFeeShow') },\n                         { max: 100, message: this.$t('field.shippingFeeShow')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.shippingFeeShow","placeholder":'请输入'+ this.$t('field.shippingFeeShow')},model:{value:(_vm.serviceOrderData.shippingFeeShow),callback:function ($$v) {_vm.$set(_vm.serviceOrderData, "shippingFeeShow", $$v)},expression:"serviceOrderData.shippingFeeShow"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.totalQuantity'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.totalQuantity',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.totalQuantity') },
                         { max: 100, message: this.$t('field.totalQuantity')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.totalQuantity',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.totalQuantity') },\n                         { max: 100, message: this.$t('field.totalQuantity')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.totalQuantity","placeholder":'请输入'+ this.$t('field.totalQuantity')},model:{value:(_vm.serviceOrderData.totalQuantity),callback:function ($$v) {_vm.$set(_vm.serviceOrderData, "totalQuantity", $$v)},expression:"serviceOrderData.totalQuantity"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.deliveryMethodType'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.deliveryMethodType',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.deliveryMethodType') },
                         { max: 100, message: this.$t('field.deliveryMethodType')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.deliveryMethodType',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.deliveryMethodType') },\n                         { max: 100, message: this.$t('field.deliveryMethodType')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.deliveryMethodType","placeholder":'请输入'+ this.$t('field.deliveryMethodType')},model:{value:(_vm.serviceOrderData.deliveryMethodType),callback:function ($$v) {_vm.$set(_vm.serviceOrderData, "deliveryMethodType", $$v)},expression:"serviceOrderData.deliveryMethodType"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.deliveryMethodDesc'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.deliveryMethodDesc',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.deliveryMethodDesc') },
                         { max: 100, message: this.$t('field.deliveryMethodDesc')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.deliveryMethodDesc',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.deliveryMethodDesc') },\n                         { max: 100, message: this.$t('field.deliveryMethodDesc')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.deliveryMethodDesc","placeholder":'请输入'+ this.$t('field.deliveryMethodDesc')},model:{value:(_vm.serviceOrderData.deliveryMethodDesc),callback:function ($$v) {_vm.$set(_vm.serviceOrderData, "deliveryMethodDesc", $$v)},expression:"serviceOrderData.deliveryMethodDesc"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.logisticsModel'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.logisticsModel',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.logisticsModel') },
                         { max: 100, message: this.$t('field.logisticsModel')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.logisticsModel',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.logisticsModel') },\n                         { max: 100, message: this.$t('field.logisticsModel')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.logisticsModel","placeholder":'请输入'+ this.$t('field.logisticsModel')},model:{value:(_vm.serviceOrderData.logisticsModel),callback:function ($$v) {_vm.$set(_vm.serviceOrderData, "logisticsModel", $$v)},expression:"serviceOrderData.logisticsModel"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.logisticsModelDesc'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.logisticsModelDesc',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.logisticsModelDesc') },
                         { max: 100, message: this.$t('field.logisticsModelDesc')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.logisticsModelDesc',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.logisticsModelDesc') },\n                         { max: 100, message: this.$t('field.logisticsModelDesc')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.logisticsModelDesc","placeholder":'请输入'+ this.$t('field.logisticsModelDesc')},model:{value:(_vm.serviceOrderData.logisticsModelDesc),callback:function ($$v) {_vm.$set(_vm.serviceOrderData, "logisticsModelDesc", $$v)},expression:"serviceOrderData.logisticsModelDesc"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.payMethodType'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.payMethodType',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.payMethodType') },
                         { max: 100, message: this.$t('field.payMethodType')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.payMethodType',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.payMethodType') },\n                         { max: 100, message: this.$t('field.payMethodType')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.payMethodType","placeholder":'请输入'+ this.$t('field.payMethodType')},model:{value:(_vm.serviceOrderData.payMethodType),callback:function ($$v) {_vm.$set(_vm.serviceOrderData, "payMethodType", $$v)},expression:"serviceOrderData.payMethodType"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.payMethodTypeDesc'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.payMethodTypeDesc',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.payMethodTypeDesc') },
                         { max: 100, message: this.$t('field.payMethodTypeDesc')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.payMethodTypeDesc',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.payMethodTypeDesc') },\n                         { max: 100, message: this.$t('field.payMethodTypeDesc')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.payMethodTypeDesc","placeholder":'请输入'+ this.$t('field.payMethodTypeDesc')},model:{value:(_vm.serviceOrderData.payMethodTypeDesc),callback:function ($$v) {_vm.$set(_vm.serviceOrderData, "payMethodTypeDesc", $$v)},expression:"serviceOrderData.payMethodTypeDesc"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.isSelfDelivery'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.isSelfDelivery',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.isSelfDelivery') },
                         { max: 100, message: this.$t('field.isSelfDelivery')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.isSelfDelivery',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.isSelfDelivery') },\n                         { max: 100, message: this.$t('field.isSelfDelivery')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.isSelfDelivery","placeholder":'请输入'+ this.$t('field.isSelfDelivery')},model:{value:(_vm.serviceOrderData.isSelfDelivery),callback:function ($$v) {_vm.$set(_vm.serviceOrderData, "isSelfDelivery", $$v)},expression:"serviceOrderData.isSelfDelivery"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.pickupStockName'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.pickupStockInfo.pickupStockName',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.pickupStockName') },
                         { max: 100, message: this.$t('field.pickupStockName')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.pickupStockInfo.pickupStockName',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.pickupStockName') },\n                         { max: 100, message: this.$t('field.pickupStockName')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.pickupStockInfo.pickupStockName","placeholder":'请输入'+ this.$t('field.pickupStockName')},model:{value:(_vm.serviceOrderData.pickupStockInfo.pickupStockName),callback:function ($$v) {_vm.$set(_vm.serviceOrderData.pickupStockInfo, "pickupStockName", $$v)},expression:"serviceOrderData.pickupStockInfo.pickupStockName"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.contactName'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.pickupStockInfo.contactName',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.contactName') },
                         { max: 100, message: this.$t('field.contactName')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.pickupStockInfo.contactName',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.contactName') },\n                         { max: 100, message: this.$t('field.contactName')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.pickupStockInfo.contactName","placeholder":'请输入'+ this.$t('field.contactName')},model:{value:(_vm.serviceOrderData.pickupStockInfo.contactName),callback:function ($$v) {_vm.$set(_vm.serviceOrderData.pickupStockInfo, "contactName", $$v)},expression:"serviceOrderData.pickupStockInfo.contactName"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.contactPhone'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.pickupStockInfo.contactPhone',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.contactPhone') },
                         { max: 100, message: this.$t('field.contactPhone')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.pickupStockInfo.contactPhone',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.contactPhone') },\n                         { max: 100, message: this.$t('field.contactPhone')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.pickupStockInfo.contactPhone","placeholder":'请输入'+ this.$t('field.contactPhone')},model:{value:(_vm.serviceOrderData.pickupStockInfo.contactPhone),callback:function ($$v) {_vm.$set(_vm.serviceOrderData.pickupStockInfo, "contactPhone", $$v)},expression:"serviceOrderData.pickupStockInfo.contactPhone"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.fullAddress'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.pickupStockInfo.fullAddress',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.fullAddress') },
                         { max: 100, message: this.$t('field.fullAddress')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.pickupStockInfo.fullAddress',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.fullAddress') },\n                         { max: 100, message: this.$t('field.fullAddress')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.pickupStockInfo.fullAddress","placeholder":'请输入'+ this.$t('field.fullAddress')},model:{value:(_vm.serviceOrderData.pickupStockInfo.fullAddress),callback:function ($$v) {_vm.$set(_vm.serviceOrderData.pickupStockInfo, "fullAddress", $$v)},expression:"serviceOrderData.pickupStockInfo.fullAddress"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.receiverName'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.serviceOrderReturnShippingAddress.receiverName',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.receiverName') },
                         { max: 100, message: this.$t('field.receiverName')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.serviceOrderReturnShippingAddress.receiverName',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.receiverName') },\n                         { max: 100, message: this.$t('field.receiverName')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.serviceOrderReturnShippingAddress.receiverName","placeholder":'请输入'+ this.$t('field.receiverName')},model:{value:(_vm.serviceOrderData.serviceOrderReturnShippingAddress.receiverName),callback:function ($$v) {_vm.$set(_vm.serviceOrderData.serviceOrderReturnShippingAddress, "receiverName", $$v)},expression:"serviceOrderData.serviceOrderReturnShippingAddress.receiverName"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.receiverPhone'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.serviceOrderReturnShippingAddress.receiverPhone',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.receiverPhone') },
                         { max: 100, message: this.$t('field.receiverPhone')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.serviceOrderReturnShippingAddress.receiverPhone',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.receiverPhone') },\n                         { max: 100, message: this.$t('field.receiverPhone')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.serviceOrderReturnShippingAddress.receiverPhone","placeholder":'请输入'+ this.$t('field.receiverPhone')},model:{value:(_vm.serviceOrderData.serviceOrderReturnShippingAddress.receiverPhone),callback:function ($$v) {_vm.$set(_vm.serviceOrderData.serviceOrderReturnShippingAddress, "receiverPhone", $$v)},expression:"serviceOrderData.serviceOrderReturnShippingAddress.receiverPhone"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.fullAddress'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.serviceOrderReturnShippingAddress.fullAddress',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.fullAddress') },
                         { max: 100, message: this.$t('field.fullAddress')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.serviceOrderReturnShippingAddress.fullAddress',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.fullAddress') },\n                         { max: 100, message: this.$t('field.fullAddress')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.serviceOrderReturnShippingAddress.fullAddress","placeholder":'请输入'+ this.$t('field.fullAddress')},model:{value:(_vm.serviceOrderData.serviceOrderReturnShippingAddress.fullAddress),callback:function ($$v) {_vm.$set(_vm.serviceOrderData.serviceOrderReturnShippingAddress, "fullAddress", $$v)},expression:"serviceOrderData.serviceOrderReturnShippingAddress.fullAddress"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.receiverName'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.serviceOrderResendShippingAddress.receiverName',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.receiverName') },
                         { max: 100, message: this.$t('field.receiverName')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.serviceOrderResendShippingAddress.receiverName',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.receiverName') },\n                         { max: 100, message: this.$t('field.receiverName')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.serviceOrderResendShippingAddress.receiverName","placeholder":'请输入'+ this.$t('field.receiverName')},model:{value:(_vm.serviceOrderData.serviceOrderResendShippingAddress.receiverName),callback:function ($$v) {_vm.$set(_vm.serviceOrderData.serviceOrderResendShippingAddress, "receiverName", $$v)},expression:"serviceOrderData.serviceOrderResendShippingAddress.receiverName"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.receiverPhone'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.serviceOrderResendShippingAddress.receiverPhone',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.receiverPhone') },
                         { max: 100, message: this.$t('field.receiverPhone')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.serviceOrderResendShippingAddress.receiverPhone',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.receiverPhone') },\n                         { max: 100, message: this.$t('field.receiverPhone')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.serviceOrderResendShippingAddress.receiverPhone","placeholder":'请输入'+ this.$t('field.receiverPhone')},model:{value:(_vm.serviceOrderData.serviceOrderResendShippingAddress.receiverPhone),callback:function ($$v) {_vm.$set(_vm.serviceOrderData.serviceOrderResendShippingAddress, "receiverPhone", $$v)},expression:"serviceOrderData.serviceOrderResendShippingAddress.receiverPhone"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.fullAddress'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.serviceOrderResendShippingAddress.fullAddress',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.fullAddress') },
                         { max: 100, message: this.$t('field.fullAddress')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.serviceOrderResendShippingAddress.fullAddress',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.fullAddress') },\n                         { max: 100, message: this.$t('field.fullAddress')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.serviceOrderResendShippingAddress.fullAddress","placeholder":'请输入'+ this.$t('field.fullAddress')},model:{value:(_vm.serviceOrderData.serviceOrderResendShippingAddress.fullAddress),callback:function ($$v) {_vm.$set(_vm.serviceOrderData.serviceOrderResendShippingAddress, "fullAddress", $$v)},expression:"serviceOrderData.serviceOrderResendShippingAddress.fullAddress"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.skuNo'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.serviceOrderItemList.skuNo',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.skuNo') },
                         { max: 100, message: this.$t('field.skuNo')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.serviceOrderItemList.skuNo',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.skuNo') },\n                         { max: 100, message: this.$t('field.skuNo')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.serviceOrderItemList.skuNo","placeholder":'请输入'+ this.$t('field.skuNo')},model:{value:(_vm.serviceOrderData.serviceOrderItemList.skuNo),callback:function ($$v) {_vm.$set(_vm.serviceOrderData.serviceOrderItemList, "skuNo", $$v)},expression:"serviceOrderData.serviceOrderItemList.skuNo"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.skuImage'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.serviceOrderItemList.skuImage',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.skuImage') },
                         { max: 100, message: this.$t('field.skuImage')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.serviceOrderItemList.skuImage',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.skuImage') },\n                         { max: 100, message: this.$t('field.skuImage')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.serviceOrderItemList.skuImage","placeholder":'请输入'+ this.$t('field.skuImage')},model:{value:(_vm.serviceOrderData.serviceOrderItemList.skuImage),callback:function ($$v) {_vm.$set(_vm.serviceOrderData.serviceOrderItemList, "skuImage", $$v)},expression:"serviceOrderData.serviceOrderItemList.skuImage"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.skuName'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.serviceOrderItemList.skuName',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.skuName') },
                         { max: 100, message: this.$t('field.skuName')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.serviceOrderItemList.skuName',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.skuName') },\n                         { max: 100, message: this.$t('field.skuName')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.serviceOrderItemList.skuName","placeholder":'请输入'+ this.$t('field.skuName')},model:{value:(_vm.serviceOrderData.serviceOrderItemList.skuName),callback:function ($$v) {_vm.$set(_vm.serviceOrderData.serviceOrderItemList, "skuName", $$v)},expression:"serviceOrderData.serviceOrderItemList.skuName"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.quantity'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.serviceOrderItemList.quantity',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.quantity') },
                         { max: 100, message: this.$t('field.quantity')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.serviceOrderItemList.quantity',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.quantity') },\n                         { max: 100, message: this.$t('field.quantity')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.serviceOrderItemList.quantity","placeholder":'请输入'+ this.$t('field.quantity')},model:{value:(_vm.serviceOrderData.serviceOrderItemList.quantity),callback:function ($$v) {_vm.$set(_vm.serviceOrderData.serviceOrderItemList, "quantity", $$v)},expression:"serviceOrderData.serviceOrderItemList.quantity"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.tagPrice'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.serviceOrderItemList.tagPrice',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.tagPrice') },
                         { max: 100, message: this.$t('field.tagPrice')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.serviceOrderItemList.tagPrice',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.tagPrice') },\n                         { max: 100, message: this.$t('field.tagPrice')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.serviceOrderItemList.tagPrice","placeholder":'请输入'+ this.$t('field.tagPrice')},model:{value:(_vm.serviceOrderData.serviceOrderItemList.tagPrice),callback:function ($$v) {_vm.$set(_vm.serviceOrderData.serviceOrderItemList, "tagPrice", $$v)},expression:"serviceOrderData.serviceOrderItemList.tagPrice"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.dealPrice'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.serviceOrderItemList.dealPrice',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.dealPrice') },
                         { max: 100, message: this.$t('field.dealPrice')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.serviceOrderItemList.dealPrice',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.dealPrice') },\n                         { max: 100, message: this.$t('field.dealPrice')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.serviceOrderItemList.dealPrice","placeholder":'请输入'+ this.$t('field.dealPrice')},model:{value:(_vm.serviceOrderData.serviceOrderItemList.dealPrice),callback:function ($$v) {_vm.$set(_vm.serviceOrderData.serviceOrderItemList, "dealPrice", $$v)},expression:"serviceOrderData.serviceOrderItemList.dealPrice"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.totalAmount'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.serviceOrderItemList.totalAmount',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.totalAmount') },
                         { max: 100, message: this.$t('field.totalAmount')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.serviceOrderItemList.totalAmount',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.totalAmount') },\n                         { max: 100, message: this.$t('field.totalAmount')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.serviceOrderItemList.totalAmount","placeholder":'请输入'+ this.$t('field.totalAmount')},model:{value:(_vm.serviceOrderData.serviceOrderItemList.totalAmount),callback:function ($$v) {_vm.$set(_vm.serviceOrderData.serviceOrderItemList, "totalAmount", $$v)},expression:"serviceOrderData.serviceOrderItemList.totalAmount"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.dealAmount'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.serviceOrderItemList.dealAmount',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.dealAmount') },
                         { max: 100, message: this.$t('field.dealAmount')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.serviceOrderItemList.dealAmount',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.dealAmount') },\n                         { max: 100, message: this.$t('field.dealAmount')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.serviceOrderItemList.dealAmount","placeholder":'请输入'+ this.$t('field.dealAmount')},model:{value:(_vm.serviceOrderData.serviceOrderItemList.dealAmount),callback:function ($$v) {_vm.$set(_vm.serviceOrderData.serviceOrderItemList, "dealAmount", $$v)},expression:"serviceOrderData.serviceOrderItemList.dealAmount"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.tagPriceShow'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.serviceOrderItemList.tagPriceShow',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.tagPriceShow') },
                         { max: 100, message: this.$t('field.tagPriceShow')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.serviceOrderItemList.tagPriceShow',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.tagPriceShow') },\n                         { max: 100, message: this.$t('field.tagPriceShow')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.serviceOrderItemList.tagPriceShow","placeholder":'请输入'+ this.$t('field.tagPriceShow')},model:{value:(_vm.serviceOrderData.serviceOrderItemList.tagPriceShow),callback:function ($$v) {_vm.$set(_vm.serviceOrderData.serviceOrderItemList, "tagPriceShow", $$v)},expression:"serviceOrderData.serviceOrderItemList.tagPriceShow"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.dealPriceShow'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.serviceOrderItemList.dealPriceShow',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.dealPriceShow') },
                         { max: 100, message: this.$t('field.dealPriceShow')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.serviceOrderItemList.dealPriceShow',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.dealPriceShow') },\n                         { max: 100, message: this.$t('field.dealPriceShow')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.serviceOrderItemList.dealPriceShow","placeholder":'请输入'+ this.$t('field.dealPriceShow')},model:{value:(_vm.serviceOrderData.serviceOrderItemList.dealPriceShow),callback:function ($$v) {_vm.$set(_vm.serviceOrderData.serviceOrderItemList, "dealPriceShow", $$v)},expression:"serviceOrderData.serviceOrderItemList.dealPriceShow"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.totalAmountShow'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.serviceOrderItemList.totalAmountShow',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.totalAmountShow') },
                         { max: 100, message: this.$t('field.totalAmountShow')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.serviceOrderItemList.totalAmountShow',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.totalAmountShow') },\n                         { max: 100, message: this.$t('field.totalAmountShow')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.serviceOrderItemList.totalAmountShow","placeholder":'请输入'+ this.$t('field.totalAmountShow')},model:{value:(_vm.serviceOrderData.serviceOrderItemList.totalAmountShow),callback:function ($$v) {_vm.$set(_vm.serviceOrderData.serviceOrderItemList, "totalAmountShow", $$v)},expression:"serviceOrderData.serviceOrderItemList.totalAmountShow"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.dealAmountShow'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.serviceOrderItemList.dealAmountShow',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.dealAmountShow') },
                         { max: 100, message: this.$t('field.dealAmountShow')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.serviceOrderItemList.dealAmountShow',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.dealAmountShow') },\n                         { max: 100, message: this.$t('field.dealAmountShow')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.serviceOrderItemList.dealAmountShow","placeholder":'请输入'+ this.$t('field.dealAmountShow')},model:{value:(_vm.serviceOrderData.serviceOrderItemList.dealAmountShow),callback:function ($$v) {_vm.$set(_vm.serviceOrderData.serviceOrderItemList, "dealAmountShow", $$v)},expression:"serviceOrderData.serviceOrderItemList.dealAmountShow"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.unit'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'serviceOrderData.serviceOrderItemList.unit',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.unit') },
                         { max: 100, message: this.$t('field.unit')+'长度小于等于100' }]}
        ]),expression:"[ 'serviceOrderData.serviceOrderItemList.unit',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.unit') },\n                         { max: 100, message: this.$t('field.unit')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"serviceOrderData.serviceOrderItemList.unit","placeholder":'请输入'+ this.$t('field.unit')},model:{value:(_vm.serviceOrderData.serviceOrderItemList.unit),callback:function ($$v) {_vm.$set(_vm.serviceOrderData.serviceOrderItemList, "unit", $$v)},expression:"serviceOrderData.serviceOrderItemList.unit"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.tags'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-select',{attrs:{"mode":"multiple"},model:{value:(_vm.serviceOrderData.serviceOrderItemList.tags),callback:function ($$v) {_vm.$set(_vm.serviceOrderData.serviceOrderItemList, "tags", $$v)},expression:"serviceOrderData.serviceOrderItemList.tags"}},_vm._l((_vm.codeList.tags),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v(_vm._s(item.label)+" ")])}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }